export enum MODULE_TYPES {
  FROM_HELP_DESK = 0,
  FROM_CALL_CENTER = 1,
  FROM_CHAT_SERVICE = 2,
}

export enum ARCHIVE_TYPES {
  ARCHIVE_FALSE = 0,
  ARCHIVE_TRUE = 1,
}

export enum STATUS_TYPES {
  STATUS_FALSE = 0,
  STATUS_TRUE = 1,
  missed="missed",
  answer="answer",
  hungUp="hungUp"
}

export enum VIP_STATUS {
  STATUS_FALSE = null,
  STATUS_TRUE = 1,
}

export enum CALL_TYPE {
  INCOMING = 'incoming',
  OUTGOING = 'outgoing',
}
